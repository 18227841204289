<template>
    <div>
        <Navbar page="Gestão de Cookies" link="/cookies" nameLink="Cookies"  />
        <div v-if="form" class="mx-4 my-4 md:mx-8 md:my-8">            
            <div class="grid grid-cols-12 gap-4">
                <div class="col-span-12 md:col-span-6">
                    <h2 class="text-xl font-semibold mb-2">Informações sobre o site</h2>
                    <div class="bg-gray-100 px-2 py-5 rounded">
                        <h2 class="text-base font-semibold my-1">URL SITE: <span class="font-normal">{{ form.url }}</span></h2>
                        <h2 class="text-base font-semibold my-1">Acessos ao site nós últimos 7 dias: <span class="font-normal">{{ form.historicoAcessos.acessos7dias }}</span></h2>
                        <h2 class="text-base font-semibold my-1">Acessos ao site nós últimos 30 dias: <span class="font-normal">{{ form.historicoAcessos.acessos30dias }}</span></h2>
                        <h2 class="text-base font-semibold my-1">
                            Política de Privacidade: 
                            <span class="font-normal">
                                <a v-if="this.form.politica_privacidade && this.form.politica_privacidade.filename" :href="`${this.url_api}/upload?mimetype=${this.form.politica_privacidade.mimetype}&filename=${this.form.politica_privacidade.filename}&folder=empresas`" target="_blank" rel="noreferrer" class="underline">
                                    {{this.form.politica_privacidade.originalname}}
                                </a>
                            </span>
                        </h2>
                        <h2 class="text-base font-semibold my-1">
                            Relatório Geral
                            <span class="font-normal">
                                <a :href="`${url_api}/v1/cookies/relatorio/${$route.params.id}?token=${$store.state.token}`" target="_blank" rel="noreferrer" class="underline">
                                    Visualizar
                                </a>
                            </span>
                        </h2>
                    </div>
                </div>
                <div class="col-span-12 md:col-span-6">
                    <h2 class="text-xl font-semibold mb-2">Histórico acessos site <small>(últimos 20 dias)</small></h2>
                    <apexchart v-if="grafico.data.length" height="240" type="line" :options="grafico.option" :series="grafico.data"></apexchart>
                </div>
            </div>
            <div v-if="form" class="grid grid-cols-12 gap-x-2 mt-5">
                <div class="col-span-12 md:col-span-6">
                    <h2 class="text-lg font-semibold">Cookies Site <small>(Cookies identificados no site )</small></h2>
                    <a :href="`${url_api}/v1/cookies/relatorio-cookies/${$route.params.id}?token=${$store.state.token}`" target="_blank" rel="noreferrer" class="block my-1 text-sm text-blue-500 underline">Download</a>
                    <div class="grid grid-cols-12 gap-2 bg-blue-100 px-2 py-2 rounded mb-3">
                        <div class="col-span-12 md:col-span-4">
                            <h2 class="text-base font-semibold my-1">Obrigatórios:  <span class="font-normal">{{ form.totalObrigatorios }}</span></h2>
                            <h2 class="text-base font-semibold my-1">Estatísticos:  <span class="font-normal">{{ form.totalEstatisticos }}</span></h2>
                        </div>
                        <div class="col-span-12 md:col-span-4">
                            <h2 class="text-base font-semibold my-1">Marketing:  <span class="font-normal">{{ form.totalMarketing }}</span></h2>
                            <h2 class="text-base font-semibold my-1">Preferências:  <span class="font-normal">{{ form.totalPreferencias }}</span></h2>
                        </div>
                        <div class="col-span-12 md:col-span-4">
                            <h2 class="text-base font-semibold my-1">Outros:  <span class="font-normal">{{ form.totalOutros }}</span></h2>
                        </div>
                    </div>

                    <div class="shadow overflow-x-auto border-b border-gray-200 sm:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th scope="col" class="px-2 py-2 text-left text-xs font-medium text-gray-500 tracking-wider">
                                        Nome
                                    </th>
                                    <th scope="col" class="px-2 py-2 text-left text-xs font-medium text-gray-500 tracking-wider">
                                        Tipo
                                    </th>
                                    <th scope="col" class="px-2 py-2 text-left text-xs font-medium text-gray-500 tracking-wider">
                                        Plataforma
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <tr v-for="(item, idx) in form.cookies" :key="idx">
                                    <td class="px-2 py-2">
                                        <div class="text-sm text-gray-500">
                                            {{item.chave ? item.chave : ''}}
                                        </div>
                                    </td>
                                    <td class="px-2 py-2">
                                        <div class="text-sm text-gray-500">
                                            {{item.tipo ? item.tipo : ''}}
                                        </div>
                                    </td>
                                    <td class="px-2 py-2">
                                        <div class="text-sm text-gray-500">
                                            {{item.plataforma ? item.plataforma : ''}}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-span-12 md:col-span-6">
                    <h2 class="text-lg font-semibold">Ações confirmações <small>(aceitos pelos usuários) </small> </h2>
                    <a :href="`${url_api}/v1/cookies/relatorio-acesso/${$route.params.id}?token=${$store.state.token}`" target="_blank" rel="noreferrer" class="block my-1 text-sm text-blue-500 underline">Download</a>
                    <div class="grid grid-cols-12 gap-2 bg-blue-100 px-2 py-2 rounded mb-3">
                        <div class="col-span-12 md:col-span-4">
                            <h2 class="text-base font-semibold my-1">Obrigatórios:  <span class="font-normal">{{ form.aceitosObrigatorios }}</span></h2>
                            <h2 class="text-base font-semibold my-1">Estatísticos:  <span class="font-normal">{{ form.aceitosEstatisticos }}</span></h2>
                        </div>
                        <div class="col-span-12 md:col-span-4">
                            <h2 class="text-base font-semibold my-1">Marketing:  <span class="font-normal">{{ form.aceitosMarketing }}</span></h2>
                            <h2 class="text-base font-semibold my-1">Preferências:  <span class="font-normal">{{ form.aceitosPreferencias }}</span></h2>
                        </div>
                        <div class="col-span-12 md:col-span-4">
                            <h2 class="text-base font-semibold my-1">Outros:  <span class="font-normal">{{ form.aceitosOutros }}</span></h2>
                            <small>Histórico dos últimos 5 usuários</small>
                        </div>
                    </div>
                    <div class="shadow overflow-x-auto border-b border-gray-200 sm:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th scope="col" class="px-1 py-2 text-left text-xs font-medium text-gray-500 tracking-wider">
                                        Obrigatórios
                                    </th>
                                    <th scope="col" class="px-1 py-2 text-left text-xs font-medium text-gray-500 tracking-wider">
                                        Estatísticos
                                    </th>
                                    <th scope="col" class="px-1 py-2 text-left text-xs font-medium text-gray-500 tracking-wider">
                                        Marketing
                                    </th>
                                    <th scope="col" class="px-1 py-2 text-left text-xs font-medium text-gray-500 tracking-wider">
                                        Preferências
                                    </th>
                                    <th scope="col" class="px-1 py-2 text-left text-xs font-medium text-gray-500 tracking-wider">
                                        Outros
                                    </th>
                                    <th scope="col" class="px-1 py-2 text-left text-xs font-medium text-gray-500 tracking-wider">
                                        Data
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <tr v-for="(item, idx) in form.historico" :key="idx">
                                    <td class="px-1 py-2">
                                        <div class="text-sm text-gray-500">
                                            {{item.obrigatorios ? 'Sim' : 'Não '}}
                                        </div>
                                    </td>
                                    <td class="px-1 py-2">
                                        <div class="text-sm text-gray-500">
                                            {{item.estatisticos ? 'Sim' : 'Não '}}
                                        </div>
                                    </td>
                                    <td class="px-1 py-2">
                                        <div class="text-sm text-gray-500">
                                            {{item.marketing ? 'Sim' : 'Não '}}
                                        </div>
                                    </td>
                                    <td class="px-1 py-2">
                                        <div class="text-sm text-gray-500">
                                            {{item.preferencias ? 'Sim' : 'Não '}}
                                        </div>
                                    </td>
                                    <td class="px-1 py-2">
                                        <div class="text-sm text-gray-500">
                                            {{item.outros ? 'Sim' : 'Não '}}
                                        </div>
                                    </td>
                                    <td class="px-1 py-2">
                                        <div class="text-sm text-gray-500">
                                            {{ item.data | moment("DD/MM/YYYY HH:mm")}}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
               
                <div class="col-span-12 md:col-span-6">
                    <h2 class="text-lg font-semibold my-3">Política de Privacidade</h2>
                    <vue-dropzone ref="doc" id="doc" class="w-full mt-2" :options="dropzoneOptions" v-on:vdropzone-success="update">
                    </vue-dropzone>
                    <a v-if="this.form.politica_privacidade && this.form.politica_privacidade.filename" :href="`${this.url_api}/upload?mimetype=${this.form.politica_privacidade.mimetype}&filename=${this.form.politica_privacidade.filename}&folder=empresas`" target="_blank" rel="noreferrer" class="underline">
                        <br/>
                        <span class="font-semibold">Arquivo:</span>
                        {{this.form.politica_privacidade.originalname}}
                    </a>
                    <a v-if="this.form && this.form.politica_privacidade && this.form.politica_privacidade.filename" @click="form.politica_privacidade = { folder: '' }">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mt-1 mr-2" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
                            <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                        </svg>
                    </a>
                    <div>
                        <h2 class="text-lg font-semibold mt-3">Link para Política de Privacidade</h2>
                        <input type="text" v-model="form.politica_privacidade_link" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full h-8 shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                    </div>
                </div>
            </div>
            <div class="grid grid-cols-2 mt-5">
                <div class="py-3 text-left">
                    <button @click="$router.back()" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                        Voltar
                    </button>
                </div>
                <div class="py-3 text-right">
                    <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                        Salvar
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
export default {
    components: {
        vueDropzone,
    },
    data() {
        return {
            route: 'cookies',
            form: null,
            dropzoneOptions: this.$http.getDropzoneConfig(
                `v1/upload`,
                'post',
            {
                maxFiles: 1,
                addRemoveLinks: true,
                capture: true,
                dictDefaultMessage: 'Clique ou arraste a Política de Privacidade aqui',
                dictRemoveFile: 'Remover'
            }),
            grafico: {
                data: [],
                option: {
                    chart: {
                        type: "line",
                    },
                    xaxis: {
                        categories: [],
                    }
                }
            }
        }
    },
    methods: {
        async save() {
            const req = await this.$http.put(`/v1/${this.route}/`, this.form);
            if (req.data.success) {
                this.$vToastify.success("Salvo com sucesso!");
                this.$router.back();
            } else {
                this.$vToastify.error(req.data.err);
            }
        },
        update(file, response) {
            this.form.politica_privacidade = response.file;
        },
    },
    async beforeMount() {
        const id = this.$route.params.id;

        if (id) {
            const req = await this.$http.get(`/v1/${this.route}/${id}`);
            this.form = req.data.data;
            this.form.historico = req.data.historico;
            this.form.historicoAcessos = req.data.historicoAcessos;

            const reqGrafico = await this.$http.get(`/v1/${this.route}/grafico-acesso/${id}`);

            this.grafico.data = [{ name: 'Acessos', data: reqGrafico.data.acessosData }];
            this.grafico.option = this.grafico.option = {
                chart: {
                    type: "line",
                },
                xaxis: {
                    categories: reqGrafico.data.labels,
                },
            };
        }
    },
}
</script>